<template>
  <div>
    <div class="text-sm mb-1">
      <PTranslation k="CreateOrUpdateUserDialog.SecondaryLocations" />
    </div>
    <div 
      class="bg-white p-2 rounded border border-gray-400 text-sm"
      :class="{
        'bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed': disabled
      }"
    >
      <ul class="mb-2" v-if="userLocations.length > 0">
        <li class="py-1 px-2 border-b border-gray-200" v-for="(location, index) in userLocations" :key="index">          
          {{ location.name }} ({{ location.id }})          
        </li>
      </ul>    
      <div class="px-2">
        <button 
          class="underline text-green-500" 
          :class="{
            'text-green-300': disabled
          }"
          @click.prevent="editSecondaryLocationsVisible = true"
        >
          <PTranslation k="Common.Actions.Select" />...</button>
      </div>
    </div>
    <div v-if="editSecondaryLocationsVisible" class="overlay absolute inset-0 z-10 flex justify-center items-center">
      <div class="bg-white rounded-md w-full m-4 shadow-lg">
        <div class="p-4 border-b border-gray-300 bg-gray-100 rounded-t-md">
          <PTranslation k="CreateOrUpdateUserDialog.SelectSecondaryLocations" />
        </div>
        <div class="max-h-96 overflow-y-auto flex flex-col space-y-2 p-4">
          <div v-for="(location, index) in secondaryLocations" :key="index">
            <PCheckbox 
              :label="`${location.name} (${location.id})`" 
              :value="isSelected(location.id)" 
              @input="toggle(location.id)" 
            />
          </div>
        </div>
        <div class="p-4 border-t border-gray-300 rounded-b-md bg-gray-100 flex justify-end">
          <PButton @click="editSecondaryLocationsVisible = false">
            <PTranslation k="Common.Actions.Close" />
          </PButton>
        </div>
      </div>
    </div>      
  </div>
</template>

<script>
import { filter, findIndex } from "lodash"
export default {  
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    locations: {
      type: Array,
      default: () => []
    },
    userLocationId: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      locationIds: [],
      editSecondaryLocationsVisible: false     
    }
  },
  computed: {
    // secondary locations
    secondaryLocations () {
      return filter(this.locations, l => l.id !== this.userLocationId)
    },
    // secondary locations selected
    userLocations () {
      return filter(this.secondaryLocations, l => findIndex(this.value, id => id === l.id) !== -1)
    }
  },
  methods: {
    toggle (locationId) {
      let arr = [...this.value]
      const index = findIndex(this.value, id => id === locationId)      
      if (index === -1) {
        arr.push(locationId)
      }
      else {
        arr.splice(index, 1)        
      }
      this.$emit("input", arr)
    },

    isSelected (locationId) {
      return findIndex(this.userLocations, l => l.id === locationId) !== -1
    }
  },
}
</script>

<style scoped>
  .overlay {
    background-color: rgba(0,0,0,.5);
    @apply rounded-lg;
  }
</style>
