import { first } from "lodash"

export function formatPrimaryAddress (location) {
  let adr = first(location.addresses)
    if (adr !== undefined) {
      return adr.street + ', ' + adr.postalCode + ' ' + adr.postalName + ', ' + adr.countryId
    }
    return ''
}

export default { formatPrimaryAddress } 