import http from "@/http"
import { 
  map, 
  orderBy, 
  forEach,
  filter
} from 'lodash'
import { formatPrimaryAddress } from "@/services/LocationService"

const api = {

  async AddressCountryRegions () {
    return await http.get("AddressCountryRegions")
  },

  async addressDelete(id) {
    const response = await http.delete("address", { params: { id: id }})
    return response
  },

  async addressPost(locationId, name, street, postalCode, postalName, country) {
    const response = await http.post("address", {
      locationId: locationId,
      addressType: 3,
      countryId: country,
      name: name,
      street: street,
      postalCode: postalCode,
      postalName: postalName,
      include: "id,statusId,inactive,flag,street,postalCode,postalName"
    })
    return response
  },

  async addressUpdate(id, locationId, name, street, postalCode, postalName, country, inactive) {
    const response = await http.post("address", {
      id: id,
      locationId: locationId,
      addressType: 3,
      countryId: country,
      name: name,
      street: street,
      postalCode: postalCode,
      postalName: postalName,
      inactive: inactive,
      include: "id,statusId,inactive,flag,street,postalCode,postalName"
    })
    return response
  },

  async countriesGet(){
    return filter(await http.get("AddressCountryRegions"), c => c.isAvailable)
  },

  async customerLogoGet(customerId) {
    const response = await http.get("customerLogo", { params: { customerId: customerId } })
    return response
  },

  async customerLogoDelete(customerId) {
    await http.delete("customerLogo", { params: { customerId: customerId } })
  },

  async customerPost(id, name, email, phone, invoiceRef, emailInvoice, emailPurchase, emailOrderConfirmed) {
    const response = await http.post("customer", {
      id,
      name,
      email,
      phone,
      invoiceRef,
      emailInvoice,
      emailPurchase,
      emailOrderConfirmed
    })
    return response
  },

  async externalLocationDelete(id) {
    return await http.delete("externalLocation", { params: { id: id }})
  },

  async externalLocationGet(id) {
    return await http.get("externalLocation", { params: { id: id }})
  },

  async externalLocationsGet(customerId) {
    return await http.get("externalLocations", { params: { customerId: customerId, isShop: false }})
  },

  async externalLocationPost(id, idExt, street, postalCode, postalName, countryRegionId, customerId, locationId, name, contactName) {
    const response = await http.post("externalLocation", {
      id,
      idExt,
      street,
      postalCode,
      postalName,
      countryRegionId,
      customerId,
      locationId,
      name,
      contactName
    })
    return response
  },

  async inventoryCountDelete(id) {
    const response = await http.delete("inventoryCount", {
      params: {
        id
      }
    })
    return response
  },

  async inventoryCountGet(customerId, locationId) {
     return await http.get("inventoryCount",  {
       params: {
        customerId,
        locationId
       }
    })
    // return orderBy(response, ['created'], ['desc'])
  },

  async inventoryCountPost(customerId, locationId, productId, quantity) {
    const response = await http.post("inventoryCount", {
      customerId,
      locationId,
      productId,
      quantity
    })
    return response
  },

  async locationsGet(customerId) {
    
    const response = await http.get("locations", {
      params:
      {
        customerId: customerId,
        withDetails: true
      }
    })

    let result = map(response, location => {
      return {
        address: formatPrimaryAddress(location),
        ...location
      }
    })

    let sorted = orderBy(
      result,
      (element) => {
        return +element.id.split("-")[1]
      },
      "asc"
    );

    return sorted
  },

  async locationGet(locationId) {
    const response = await http.get("location", {
      params:
      {
        id: locationId
      }
    })

    return response
  },

  async locationPost(customerId, street, postalName, postalCode, country, name, nlpStock, capacity, truckTypeId) {
    const response = await http.post("location", {
      customerId: customerId,
      deliveryStreet: street,
      deliveryPostalName: postalName,
      deliveryPostalCode: postalCode,
      deliveryCountry: country,
      name: name,
      nlpStock: nlpStock,
      capacity: capacity,
      truckTypeId: truckTypeId
    })
    return response
  },

  async reportsGet() {
    return await http.get("reports")
  },

  async languagesGet () {
    return await http.get("languages")
  },

  async productsGet(locationId) {
    return await http.get("products", { params: { locationId: locationId}})
  },

  async userGet(userId) {
    return await http.get("user", { params: { id: userId } })
  },

  async usersGet(customerId) {
    let users = await http.get("users", { params: { customerId: customerId } })

    let sorted = orderBy(
      users,
      (element) => {
        return +element.loginName.split("-")[1]
      },
      "asc"
    );
    return sorted
  },

  async createOrUpdateUser(user) {
    return await http.post("user", user)

  },

  async userTasksUpdate(userId, locationId, tasks) {

    const arr = []

    forEach (tasks, task => {
      
      const data = { 
        userId: userId, 
        locationId: locationId, 
        taskId: task.id 
      }

      arr.push(task.checked
        ? http.post("WebtaskUser", data)
        : http.delete("WebtaskUser", { params: data })
      )
            
    })

    await Promise.all(arr)
  },

  async userTasks(userId, locationId) {
    return await http.get("usertasks", { params: { userId: userId, locationId: locationId }})
  },
  
  async trucktypesGet() {
    const response = await http.get("trucktypes")
    return response
  },

   async webTasksGet() {
    return await http.get("webtasks")
  }, 
  
  async webTaskUserGet(userId) {
    return await http.get("webtaskuser", { params: { userId: userId } })
  },

  async webTaskUsersGet(userId) {
    return await http.get("webtaskusers", { params: { userId: userId } })
  },
  
  async userLocationsGet(userId) {
    return await http.get("userLocations", { params: { userId: userId } })
  }  
}

export default api
